
  import { defineComponent, reactive, onBeforeMount, ref } from 'vue';
  import { useToast } from "vue-toastification";

  import AppButton from '@/components/stateless/AppButton.vue';
  import GoalsList from '@/components/GoalsList.vue';
  import Banner from '@/components/Banner.vue';

  import { router, routesNames } from '@/router';
  import { myGoalsService } from '@/services';
  import { vuex } from '@/store';
  import { IGoal } from './my-goals.types';

  export default defineComponent({
    name: 'MyGoals',

    components: { AppButton, GoalsList, Banner },

    setup() {
      const toast = useToast();
      const state = reactive({
        openedGoalsList: [] as IGoal[],
        openedGoalsTotal: 0,
        achievedGoalsTotal: 0,
        completedGoalsList: [] as IGoal[],
      });
      const openedOffset = ref<number>(0);
      const achievedOffset = ref<number>(0);

      function handleAddnewGoalClick() {
        router.push({ name: routesNames.addGoal });
      }

      function onGoalTitleClick(id: number) {
        router.push({ name: routesNames.myGoal, query: { id } });
      }

      async function fetchMoreGoals(type: 'opened' | 'achieved') {
        if (type === 'opened') {
          openedOffset.value += 3;
          await fetchGoals(type, openedOffset.value);
        } else if (type === 'achieved') {
          achievedOffset.value += 3;
          await fetchGoals(type, achievedOffset.value);
        }
      }

      function fetchGoals(type: 'opened' | 'achieved', offset = 0) {
        vuex.setLoading(true);

        return myGoalsService.fetchGoals({ status: { "$in": [type] } }, 3, offset)
          .then(({ data }) => {
            if (type === 'opened') {
              state.openedGoalsList = [...state.openedGoalsList, ...data];
            } else if (type === 'achieved') {
              state.completedGoalsList = [...state.completedGoalsList, ...data];
            }
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      async function fetchInitData() {
        vuex.setLoading(true);

        return Promise.all([
          myGoalsService.fetchGoals({ status: { "$in": ["opened"] } }, 3),
          myGoalsService.fetchGoals({ status: { "$in": ["achieved"] } }, 3)
        ])
          .then(([openedGoals, achieved]) => {
            state.openedGoalsList = openedGoals.data;
            state.openedGoalsTotal = openedGoals.totalCount;
            state.completedGoalsList = achieved.data;
            state.achievedGoalsTotal = achieved.totalCount;
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      onBeforeMount(async () => {
        await fetchInitData();
      });

      return {
        state,

        handleAddnewGoalClick,
        onGoalTitleClick,
        fetchMoreGoals
      };
    }

  });
