<template>
  <!-- A C T I V E   G O A L S  -->
  <Banner class="mb-25" />

  <div class="flex flex-col h-auto bg-white rounded-5 w-full max-h-450 p-25">
    <div class="flex justify-between items-center mb-25">
      <p class="text-primary text-1xl">My Goals</p>

      <AppButton
        v-if="state.openedGoalsList.length"
        type="secondary"
        class="md:px-20 px-10"
        icon-size="15"
        icon="plus"
        size="large"
        icon-classes="h-30 w-30"
        custom-class="h-40"
        @click="handleAddnewGoalClick"
      >
        <span class="text-md">
          Add New Goal
        </span>
      </AppButton>
    </div>

    <GoalsList
      :key="state.openedGoalsList.length"
      :goals="state.openedGoalsList"
      :goals-count="state.openedGoalsTotal"
      @view-more="fetchMoreGoals('opened')"
    />
  </div>

  <!-- C O M P L E T E D   G O A L S -->
  <div
    v-if="state.completedGoalsList.length"
    class="flex flex-col h-auto bg-white rounded-5 w-full max-h-600 p-25 mt-20"
  >
    <div class="flex justify-between items-center mb-25">
      <h1 class="text-primary text-1xl">My Completed Goals</h1>
    </div>

    <GoalsList
      :goals="state.completedGoalsList"
      :goals-count="state.achievedGoalsTotal"
      disabled-banner
      disabled-tasks
      @view-more="fetchMoreGoals('achieved')"
    />
  </div>
</template>

<script lang="ts">
  import { defineComponent, reactive, onBeforeMount, ref } from 'vue';
  import { useToast } from "vue-toastification";

  import AppButton from '@/components/stateless/AppButton.vue';
  import GoalsList from '@/components/GoalsList.vue';
  import Banner from '@/components/Banner.vue';

  import { router, routesNames } from '@/router';
  import { myGoalsService } from '@/services';
  import { vuex } from '@/store';
  import { IGoal } from './my-goals.types';

  export default defineComponent({
    name: 'MyGoals',

    components: { AppButton, GoalsList, Banner },

    setup() {
      const toast = useToast();
      const state = reactive({
        openedGoalsList: [] as IGoal[],
        openedGoalsTotal: 0,
        achievedGoalsTotal: 0,
        completedGoalsList: [] as IGoal[],
      });
      const openedOffset = ref<number>(0);
      const achievedOffset = ref<number>(0);

      function handleAddnewGoalClick() {
        router.push({ name: routesNames.addGoal });
      }

      function onGoalTitleClick(id: number) {
        router.push({ name: routesNames.myGoal, query: { id } });
      }

      async function fetchMoreGoals(type: 'opened' | 'achieved') {
        if (type === 'opened') {
          openedOffset.value += 3;
          await fetchGoals(type, openedOffset.value);
        } else if (type === 'achieved') {
          achievedOffset.value += 3;
          await fetchGoals(type, achievedOffset.value);
        }
      }

      function fetchGoals(type: 'opened' | 'achieved', offset = 0) {
        vuex.setLoading(true);

        return myGoalsService.fetchGoals({ status: { "$in": [type] } }, 3, offset)
          .then(({ data }) => {
            if (type === 'opened') {
              state.openedGoalsList = [...state.openedGoalsList, ...data];
            } else if (type === 'achieved') {
              state.completedGoalsList = [...state.completedGoalsList, ...data];
            }
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      async function fetchInitData() {
        vuex.setLoading(true);

        return Promise.all([
          myGoalsService.fetchGoals({ status: { "$in": ["opened"] } }, 3),
          myGoalsService.fetchGoals({ status: { "$in": ["achieved"] } }, 3)
        ])
          .then(([openedGoals, achieved]) => {
            state.openedGoalsList = openedGoals.data;
            state.openedGoalsTotal = openedGoals.totalCount;
            state.completedGoalsList = achieved.data;
            state.achievedGoalsTotal = achieved.totalCount;
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      onBeforeMount(async () => {
        await fetchInitData();
      });

      return {
        state,

        handleAddnewGoalClick,
        onGoalTitleClick,
        fetchMoreGoals
      };
    }

  });
</script>